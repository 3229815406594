// Import statements organized by functionality

// React and Hooks
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Third-party Components

// Firebase Authentication
import { auth } from '../../firebase'; // Adjust the path as per your project structure

// Service Imports
import {
  createVoiceflowTag,
  deleteVoiceflowTag,
} from '../../services/voiceflowService';

import {
  createProject,
  getUserProjects,
  deleteProject,
  getSubDataForProject,
  addSubDataToProject,
  uploadFile,
  deleteFileFromStorageAndFirestore,
  updateProjectName, // Import the new service function
} from '../../services/projectService';

// Context and Custom Hooks
import { useAuth } from '../../AuthContext';

// Component and Asset Imports
import { FaTrash, FaSlidersH } from 'react-icons/fa';
import SubDataModal from './SubdataModal';
import './ProjectManager.css';
import '../../styles/global.css';

import { sendLaunchRequest } from '../../services/voiceflowService';

function ProjectManager({ projects, setProjects, onLoginClick }) {
  // Authentication
  const { currentUser } = useAuth();

  // State Variables
  const [projectName, setProjectName] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [subDataFields, setSubDataFields] = useState({});
  const [currentItemTitle, setCurrentItemTitle] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [iconPreview, setIconPreview] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [semesterFilter, setSemesterFilter] = useState('All');
  const [activeFilter, setActiveFilter] = useState('All');

  // New State Variables for Editing
  const [editedProjectId, setEditedProjectId] = useState(null);
  const [editedProjectName, setEditedProjectName] = useState('');

  // React Router
  const navigate = useNavigate();

  /* ============================================================
     Effect Hooks
  ============================================================ */

  // Fetch user projects when the current user changes
  useEffect(() => {
    if (currentUser) {
      getUserProjects(currentUser.uid).then(setProjects);
    }
  }, [currentUser, setProjects]);

  // Update filtered projects when projects, searchTerm, or activeFilter changes
  useEffect(() => {
    let updatedProjects = projects;

    if (searchTerm) {
      updatedProjects = updatedProjects.filter((project) =>
        project.projectName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (activeFilter && activeFilter !== 'All') {
      updatedProjects = updatedProjects.filter(
        (project) => project.semester === activeFilter
      );
    }

    setFilteredProjects(updatedProjects);
  }, [projects, searchTerm, activeFilter]);

  /* useEffect(() => {
    if (!currentUser) {
      onLoginClick(); // Trigger the login modal if no user is logged in
    }
  }, [currentUser, onLoginClick]); */
  
  /* ============================================================
     Event Handlers
  ============================================================ */

  // Handle project creation
  const handleCreateProject = async () => {
    if (projectName.trim() === '') return;
  
    const projectId = await createProject(projectName, onLoginClick);
  
    if (projectId) {
      setProjects([...projects, { id: projectId, projectName }]);
      setProjectName('');
  
      sendLaunchRequest(projectId);
  
      await createVoiceflowTag(projectId); // Ensure this completes
      //console.log('Navigating to AIChatPage with itemId:', projectId);
      navigate('/ai-chat', { state: { itemId: projectId } });
    }
  };
  

  // Handle project deletion
  const handleDeleteProject = async () => {
    if (projectToDelete) {
      // Delete the project from the database
      await deleteProject(projectToDelete);

      // Remove the project from the projects state
      setProjects(projects.filter((project) => project.id !== projectToDelete));

      // Check if the deleted project is the last used project in localStorage
      const lastUsedProjectId = localStorage.getItem(
        `lastUsedProjectId_${auth.currentUser?.uid}`
      );

      if (lastUsedProjectId === projectToDelete) {
        // Remove the project ID from localStorage
        localStorage.removeItem(`lastUsedProjectId_${auth.currentUser?.uid}`);
      }

      // Close the confirmation modal
      setShowConfirmation(false);
      setProjectToDelete(null);

      //console.log(
        //`Project ${projectToDelete} deleted successfully and removed from localStorage.`
     // );
    }
  };

  // Open sub-data modal for a project
  const openSubDataModal = async (projectId, projectName) => {
    setCurrentProjectId(projectId);
    setCurrentItemTitle(projectName);
    setIsModalVisible(true);

    const subData = await getSubDataForProject(projectId);
    if (subData) {
      setSubDataFields(subData); // Set the fields
      setUploadedFiles(subData.files || []); // Set the files
    }
  };

  // Handle adding sub-data to a project
  const handleAddSubData = async () => {
    if (!currentProjectId) return;
  
    try {
      // Upload new files
      const newFileUploadPromises = uploadedFiles
        .filter((file) => typeof file === 'object')
        .map((file) => uploadFile(file, currentProjectId));
  
      const newFileURLs = await Promise.all(newFileUploadPromises);
      const allFileURLs = [
        ...uploadedFiles.filter((file) => typeof file === 'string'),
        ...newFileURLs,
      ];
  
      const subDataWithFiles = {
        ...subDataFields,
        files: allFileURLs,
        icon: iconPreview,
        backgroundColor: subDataFields.backgroundColor || '#ffffff',
      };
  
      await addSubDataToProject(currentProjectId, subDataWithFiles);
  
      // Update the projects state
      const updatedProjects = projects.map((project) =>
        project.id === currentProjectId
          ? { ...project, ...subDataWithFiles } // Update the project with new data
          : project
      );
  
      setProjects(updatedProjects);
  
      // Reapply the filter immediately after updating projects
      let filtered = updatedProjects;
  
      if (searchTerm) {
        filtered = filtered.filter((project) =>
          project.projectName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
  
      if (activeFilter && activeFilter !== 'All') {
        filtered = filtered.filter((project) => project.semester === activeFilter);
      }
  
      setFilteredProjects(filtered);
  
      // Clear uploaded files
      setUploadedFiles([]);
    } catch (error) {
      console.error('Error saving sub-data:', error);
    }
  
    // Reset fields and close modal
    setSubDataFields({});
    setIconPreview(null);
    setIsModalVisible(false);
  };
  

  // Handle file input change
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
  };

  // Handle file deletion
  const handleDeleteFile = async (index) => {
    const fileToDelete = uploadedFiles[index];
    const tagLabel = currentProjectId; // Assuming the tagLabel is the same as the project ID

    // Remove the file from the UI
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(updatedFiles);

    try {
      // Delete the file from Firebase and Firestore
      await deleteFileFromStorageAndFirestore(currentProjectId, fileToDelete);

      // Delete the associated tag in Voiceflow
      await deleteVoiceflowTag(tagLabel);
      //console.log(`Tag with label ${tagLabel} deleted successfully.`);
    } catch (error) {
      console.error('Error deleting file or tag:', error);
    }
  };

  // Open confirmation modal for project deletion
  const openConfirmationBox = (projectId) => {
    setProjectToDelete(projectId);
    setShowConfirmation(true);
  };

  // Close confirmation modal
  const closeConfirmationBox = () => {
    setShowConfirmation(false);
    setProjectToDelete(null);
  };

  // Navigate to AI Chat page for a project
  const handleProjectCardClick = (projectId) => {
    //console.log('Project clicked:', projectId);
    navigate('/ai-chat', { state: { itemId: projectId } });
  };

  // Handle initiating the edit mode
  const handleEditProjectName = (projectId, projectName) => {
    setEditedProjectId(projectId);
    setEditedProjectName(projectName);
  };

  // Handle saving the edited project name
  const handleSaveProjectName = async (projectId) => {
    if (editedProjectName.trim() === '') {
      // Do not allow empty project names
      return;
    }

    try {
      // Update the project name in the database
      await updateProjectName(projectId, editedProjectName);

      // Update the project in the local state
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project.id === projectId
            ? { ...project, projectName: editedProjectName }
            : project
        )
      );

      // Reset editing state
      setEditedProjectId(null);
      setEditedProjectName('');
    } catch (error) {
      console.error('Error updating project name:', error);
    }
  };

  // Handle canceling the edit mode
  const handleCancelEdit = () => {
    setEditedProjectId(null);
    setEditedProjectName('');
  };

  /* ============================================================
     Render Component
  ============================================================ */

  return (
    <div className="project-manager">
      {/* Task Bar */}
      <div className="task-bar">
        {/* Search Input */}
        <input
          type="text"
          placeholder="Search my subjects..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />

        {/* Semester Filter */}
       {/* Semester Filter */}
<div className="filter-tabs">
  <button
    onClick={() => setActiveFilter('All')}
    className={activeFilter === 'All' ? 'active' : ''}
  >
    All
    <span className="tooltip">Show all subjects</span>
  </button>
  <button
    onClick={() => setActiveFilter('Spring')}
    className={activeFilter === 'Spring' ? 'active' : ''}
  >
    Spring
    <span className="tooltip">Show my subjects for Spring</span>
  </button>
  <button
    onClick={() => setActiveFilter('Summer')}
    className={activeFilter === 'Summer' ? 'active' : ''}
  >
    Summer
    <span className="tooltip">Show my subjects for Summer</span>
  </button>
  <button
    onClick={() => setActiveFilter('Fall')}
    className={activeFilter === 'Fall' ? 'active' : ''}
  >
    Fall
    <span className="tooltip">Show my subjects for Fall</span>
  </button>
  <button
    onClick={() => setActiveFilter('Winter')}
    className={activeFilter === 'Winter' ? 'active' : ''}
  >
    Winter
    <span className="tooltip">Show my subjects for Winter</span>
  </button>
</div>

      </div>

      {/* Project Content Area */}
      <div className="project-content-area" id="content-area">
        <div className="project-grid">
          {/* Add Project Card */}
          <div className="project-card add-project-card">
            <input
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder="Enter Subject Name"
              className="project-input"
            />
            <button className="create-project-button" onClick={handleCreateProject}>
              Create New Subject
            </button>
          </div>

          {/* Project Cards */}
          {filteredProjects.map((project) => (
            <div
              key={project.id}
              className="project-card"
              style={{ backgroundColor: project.backgroundColor || 'white' }}
              onClick={() => handleProjectCardClick(project.id)}
            >
              {editedProjectId === project.id ? (
                // Editing mode
                <input
                  type="text"
                  value={editedProjectName}
                  onChange={(e) => setEditedProjectName(e.target.value)}
                  onBlur={() => handleSaveProjectName(project.id)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSaveProjectName(project.id);
                    } else if (e.key === 'Escape') {
                      handleCancelEdit();
                    }
                  }}
                  autoFocus
                  className="edit-project-input"
                  onClick={(e) => e.stopPropagation()} // Prevent click from going to parent
                />
              ) : (
                // Display mode
                <h2
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click from going to parent
                    handleEditProjectName(project.id, project.projectName);
                  }}
                >
                  {project.projectName}
                </h2>
              )}
            <button
  className="add-button"
  onClick={(e) => {
    e.stopPropagation();
    openSubDataModal(project.id, project.projectName);
  }}
>
  <FaSlidersH />
  <span className="tooltip">Edit this Subject</span>
</button>

              <div className="project-footer">
                <span className="project-users">✏️</span>
                <button className="project-star">📚</button>
              </div>
              <button
  className="delete-button"
  onClick={(e) => {
    e.stopPropagation();
    openConfirmationBox(project.id);
  }}
  aria-label="Delete project"
>
  <FaTrash />
  <span className="tooltip">Delete this subject</span>
</button>


            </div>
          ))}
        </div>
      </div>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <>
          <div className="overlay"></div>
          <div className="confirmation-box">
            <p>
              Are you sure you want to delete this project? This cannot be undone.
            </p>
            <div className="confirmation-buttons">
              <button className="confirm-button" onClick={handleDeleteProject}>
                Yes, Delete
              </button>
              <button className="cancel-button" onClick={closeConfirmationBox}>
                Cancel
              </button>
            </div>
          </div>
        </>
      )}

      {/* SubData Modal */}
      <SubDataModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onSubmit={handleAddSubData}
        subDataFields={subDataFields}
        setSubDataFields={setSubDataFields}
        currentItemTitle={currentItemTitle}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        handleFileChange={handleFileChange}
        iconPreview={iconPreview}
        setIconPreview={setIconPreview}
        handleDeleteFile={handleDeleteFile}
      />
    </div>
  );
}
export default ProjectManager;
