import axios from 'axios';
const apiKey = process.env.REACT_APP_VOICEFLOW_API_KEY;
const V2APIKey=process.env.REACT_APP_V2_AI_API_KEY;

//create a tag in Voiceflow
export async function createVoiceflowTag(tagLabel) {
  try {
    const response = await axios.post(
      'https://api.voiceflow.com/v3alpha/knowledge-base/tags',
      {
        data: { label: tagLabel },
      },
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          Authorization: apiKey
          ,
        },
      }
    );

    //console.log(`Tag created in Voiceflow with label: ${tagLabel}`);
    return response.data;
  } catch (error) {
    console.error('Error creating Voiceflow tag:', error);
    throw error;
  }
}
// Delete a tag from Voiceflow by its label
export async function deleteVoiceflowTag(tagLabel) {
    const options = {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        Authorization: apiKey,
      },
    };
  
    try {
      const response = await axios.delete(`https://api.voiceflow.com/v3alpha/knowledge-base/tags/${tagLabel}`, options);
      //console.log(`Tag with label ${tagLabel} deleted successfully.`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting tag with label ${tagLabel}:`, error);
      throw error;
    }
  }

  export async function uploadDocumentToVoiceflow(documentUrl) {
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json; charset=utf-8',
            Authorization: apiKey,  // Ensure API key is correctly loaded
        },
        data: {
            data: {
                type: 'url',
                url: documentUrl,
            },
        },
    };

    try {
        const response = await axios('https://api.voiceflow.com/v3alpha/knowledge-base/docs/upload?maxChunkSize=1000', options);
        //console.log('Document uploaded successfully:', response.data);
        return response.data; // Returns the document ID and other details
    } catch (error) {
        console.error('Error uploading document to Voiceflow:', error.response?.data || error.message);
        throw error; // Re-throw the error to be handled by calling functions
    }
}

// Wait for the document to be ready for tagging
async function waitForDocumentReady(documentId, timeout = 30000) {
    const interval = 2000; // Poll every 2 seconds
    let elapsedTime = 0;

    while (elapsedTime < timeout) {
        try {
            const status = await checkDocumentStatus(documentId);
            if (status === "SUCCESS") {
                return true; // Document is ready
            }
        } catch (error) {
            console.error(`Error checking document status: ${error.message}`);
        }

        // Wait before retrying
        await new Promise(resolve => setTimeout(resolve, interval));
        elapsedTime += interval;
    }

    console.error("Document processing timeout.");
    return false; // Timeout occurred, document not ready
}

// Attach a tag to a document once it is ready
export async function attachTagToDocument(documentId, tagLabel) {
    const isReady = await waitForDocumentReady(documentId);
    if (!isReady) {
        console.error("Document not ready for tagging.");
        return;
    }

    try {
        const response = await axios.post(
            `https://api.voiceflow.com/v3alpha/knowledge-base/docs/${documentId}/tags/attach`,
            {
                data: {
                    tags: [tagLabel],
                },
            },
            {
                headers: {
                    'content-type': 'application/json',
                    Authorization: apiKey,
                },
            }
        );

        //console.log(`Tag attached to document ID: ${documentId}`);
        return response.data; // Successfully attached the tag
    } catch (error) {
        console.error('Error attaching tag to document:', error.response?.data || error.message);
        throw error; // Re-throw the error to be handled by calling functions
    }
}

// Check the status of a document
async function checkDocumentStatus(documentId) {
    try {
        const response = await axios.get(
            `https://api.voiceflow.com/v3alpha/knowledge-base/docs/${documentId}`,
            {
                headers: {
                    accept: 'application/json',
                    Authorization: apiKey,
                },
            }
        );
        return response.data.data.status.type; // Return document status
    } catch (error) {
        console.error(`Error checking document status: ${error.message}`);
        throw error; // Re-throw the error to be handled
    }
}

const projectIdV2 = process.env.V2_AI_PROJECT_ID;

export async function sendLaunchRequest(userId) {
    try {
      const response = await axios.post(
        `https://general-runtime.voiceflow.com/v2/project/670051167305f4f9dfa409a7/user/${userId}/interact/stream`,
        {
          action: { type: 'launch' },
        },
        {
          headers: {
            accept: 'text/event-stream',
            'content-type': 'application/json',
            Authorization: V2APIKey, // Ensure VF_API_KEY is in your .env file
          },
          params: {
            environment: 'production',
            completion_events: false,
            state: false,
          },
        }
      );
  
      //console.log('Launch request sent successfully');
      return response.data;
    } catch (error) {
      console.error('Error sending launch request:', error);
      throw error;
    }
  }
