import React, { useState } from 'react';
import { FaCompress, FaExpand } from 'react-icons/fa';
import './TopBar.css';
import '../../styles/global.css';

function TopBar({ title, projectName }) {
  const [isMinimized, setIsMinimized] = useState(false);

  const toggleTopBar = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <div className={`top-bar ${isMinimized ? 'minimized' : ''}`}>
      {!isMinimized && (
        <div className="top-bar-content">
          <span className="top-bar-title">{title}</span>
          {projectName && (
            <span className="top-bar-project-name"> | {projectName}</span>
          )}
        </div>
      )}
      <div className="toggle-topbar-button" onClick={toggleTopBar}>
        {isMinimized ? <FaExpand /> : <FaCompress />}
      </div>
    </div>
  );
}

export default TopBar;
