// Import statements organized by functionality

// React and React hooks
import React, { useState, useEffect } from 'react';
import { COMPANY_NAME } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Firebase imports
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';

// Routing imports
import { useLocation } from 'react-router-dom';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
// Icon imports (only the icons that are used)
import {
  FaHome,
  FaProjectDiagram,
  FaSun,
  FaMoon,
  FaBars,
  FaCog,
  FaUser,
  FaCalculator,
  FaFacebookMessenger,
  FaTools,
  FaArrowRight,
  FaHandsHelping,
  FaBook
} from 'react-icons/fa';

// Component imports
import SettingsBox from '../Settings/SettingsBox';
import ClipLoader from 'react-spinners/ClipLoader';

// Asset imports
import logo from '../../assets/logo/logo.png';

// CSS import
import './Sidebar.css';
import '../../styles/global.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


function Sidebar({
  onMenuItemClick,
  onProfileClick,
  userEmail,
  isDarkMode,
  onToggleDarkMode,
}) {
  // State variables
  const [isMinimized, setIsMinimized] = useState(false);
  const [activeItem, setActiveItem] = useState('Home');
  const [isLoading, setIsLoading] = useState(false);
  const [showSettingsBox, setShowSettingsBox] = useState(false);

  // Hooks
  const location = useLocation();

  // Mapping of pathnames to active items
  const pathnameToActiveItem = {
    '/ai-chat': 'AI Chat',
    '/projects': 'My Projects',
    '/calculator': 'Ai Calculator',
    // Add more paths here if needed
  };

  // Update activeItem based on current pathname
  useEffect(() => {
    const active = pathnameToActiveItem[location.pathname] || 'Home';
    setActiveItem(active);
  }, [location.pathname]);

  // Handle loading state and store activeItem in localStorage
  useEffect(() => {
    localStorage.setItem('activeItem', activeItem);

    if (activeItem === 'AI Chat') {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      return () => clearTimeout(timer); // Cleanup the timeout on unmount
    } else {
      setIsLoading(false);
    }
  }, [activeItem]);

  // Add or remove dark mode class on body element
  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode'); // Add dark mode class to the body
    } else {
      document.body.classList.remove('dark-mode'); // Remove dark mode class from the body
    }
  }, [isDarkMode]);

  // Automatically minimize sidebar on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMinimized(window.innerWidth <= 1300);
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Handlers
  const toggleSidebar = () => {
    setIsMinimized(!isMinimized);
  };

  const handleMenuItemClick = (itemName) => {
    setActiveItem(itemName);
    onMenuItemClick(itemName);
  };

  const handleBadgeClick = () => {
    setShowSettingsBox(!showSettingsBox);
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('User signed out');
  
        // Remove student info from local storage
        localStorage.removeItem('studentInfo'); // Replace 'studentInfo' with the exact key you used
  
        // Optionally, remove any other items you want to clear on logout
        // localStorage.removeItem('anotherItemKey');
  
        // Force a full page refresh
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };
  

  // Utility functions
  const getInitial = (email) => {
    return email ? email.charAt(0).toUpperCase() : '!';
  };

  return (
    <div className={`sidebar ${isMinimized ? 'minimized' : ''}`}>
  {/* Toggle Sidebar Button */}
  <div 
    className={`toggle-button ${!isMinimized ? 'rotated' : ''}`} 
    onClick={toggleSidebar}
  >
    <FontAwesomeIcon icon={faArrowRightFromBracket} />
  </div>

      {/* Logo Section */}
      <div
  className={`logo ${activeItem === 'My Projects' ? 'active' : ''}`}
  onClick={() => handleMenuItemClick('My Projects')}
  title={isMinimized ? 'My Projects' : ''}
  style={{ cursor: 'pointer' }}
>
  <img src={logo} alt="Logo" className="logo-img" />
  {!isMinimized && <span className="lightning-text">{COMPANY_NAME}</span>}
</div>

<div className="divider-line"></div>


      {/* Navigation Menu */}
      <nav>
        <ul>
          {/* Ai Calculator */}
          <li className={activeItem === 'Ai Calculator' ? 'active' : ''}>
            <a
              href="#"
              onClick={() => handleMenuItemClick('Ai Calculator')}
              title={isMinimized ? 'Ai Calculator' : ''}
            >
              <FaCalculator className="icon" />
              {!isMinimized && 'Ai Calculator'}
            </a>
          </li>

          {/* AI Chat 
          <li className={activeItem === 'AI Chat' ? 'active' : ''}>
            <a
              href="#"
              onClick={() => handleMenuItemClick('AI Chat')}
              title={isMinimized ? 'AI Chat' : ''}
            >
              <FaFacebookMessenger className="icon" />
              {!isMinimized && 'AI Chat'}
            </a>
          </li>
          */}

          {/* My Projects */}
          <li className={activeItem === 'My Projects' ? 'active' : ''}>
            <a
              href="#"
              onClick={() => handleMenuItemClick('My Projects')}
              title={isMinimized ? 'My Projects' : ''}
            >
              <FaProjectDiagram className="icon" />
              {!isMinimized && 'My Subjects'}
            </a>
          </li>

          <div className="divider-line"></div>

          {/* Settings 
          <li className={activeItem === 'Settings' ? 'active' : ''}>
            <a
              href="#"
              onClick={() => handleMenuItemClick('Settings')}
              title={isMinimized ? 'Settings' : ''}
            >
              /* <FaUser className="icon" />
              {!isMinimized && 'Profile'}
            </a>
          </li>
*/}
           {/* Tutorials*/}
           <li className={activeItem === 'Help' ? 'active' : ''}>
            <a
              href="#"
              onClick={() => handleMenuItemClick('Help')}
              title={isMinimized ? 'Help' : ''}
            >
              <FaBook className="icon" />
              {!isMinimized && 'Help'}
            </a>
          </li>

          <div className="divider-line"></div>
        </ul>
      </nav>

      {/* Settings Section */}
      <div className="settings">
        {/* Dark Mode Toggle */}
        <div className="light-dark-toggle">
          <button onClick={onToggleDarkMode} title={isMinimized ? (isDarkMode ? 'Light Mode' : 'Dark Mode') : ''}>
            {isDarkMode ? <FaSun className="icon" /> : <FaMoon className="icon" />}
            {!isMinimized && (isDarkMode ? 'Light Mode' : 'Dark Mode')}
          </button>
        </div>

        <div className="divider-line"></div>

        {/* User Profile */}
        <div className="user-profile" onClick={handleBadgeClick} title={isMinimized ? 'Profile' : ''}>
          <div className="user-badge-wrapper">
            {!isMinimized && <FaUser className="user-icon" />}
            <div className="user-badge">{getInitial(userEmail)}</div>
          </div>
          {showSettingsBox && (
            <SettingsBox
              isLoggedIn={!!userEmail}
              onLogout={handleLogout}
              onLogin={onProfileClick}
            />
          )}
        </div>
      </div>

      {/* Loading Spinner */}
      {isLoading && activeItem === 'AI Chat' && (
        <div className="spinner-overlay">
          <ClipLoader color="#007bff" size={30} />
        </div>
      )}
    </div>
  );
}

export default Sidebar;
