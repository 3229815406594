import React, { useState, useEffect, useRef } from 'react';
import './VoiceTranscription.css';
import { FaMicrophone, FaStop, FaTimesCircle } from 'react-icons/fa';
import VoiceHologram from './VoiceHologram';
import voiceOptions from '../../Page Options/voiceOptions.js'; // Import the voice options
import { useVoiceContext } from '../../VoiceContext'; // Import useVoiceContext

function VoiceTranscription() {
  const { currentVoiceId, setCurrentVoiceId } = useVoiceContext(); // Use context to get and set voice ID
  const [transcript, setTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [audioData, setAudioData] = useState(0);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isHologramActive, setIsHologramActive] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(currentVoiceId); // Set initial voice to context's value
  const recognitionRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const sourceRef = useRef(null);
  const audioPlayerRef = useRef(null);

  useEffect(() => {
    console.log('Initializing speech recognition...');
    if (!('webkitSpeechRecognition' in window || 'SpeechRecognition' in window)) {
      alert('Your browser does not support speech recognition.');
      return;
    }

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = 'en-US';

    recognitionRef.current.onresult = (event) => {
      let interimTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcriptSegment = event.results[i][0].transcript;
        interimTranscript += transcriptSegment;
        console.log(`Transcribed segment [${i}]: ${transcriptSegment}`);
      }
      setTranscript(interimTranscript);
    };

    recognitionRef.current.onerror = (event) => {
      console.error('Speech recognition error:', event.error);
    };

    return () => {
      recognitionRef.current && recognitionRef.current.stop();
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      console.log('Speech recognition and audio context cleaned up.');
    };
  }, []);

  useEffect(() => {
    // Sync local state with context value when the context changes
    setSelectedVoice(currentVoiceId);
    console.log(`Updated selectedVoice to match currentVoiceId: ${currentVoiceId}`);
  }, [currentVoiceId]);


  useEffect(() => {
    const interval = setInterval(() => {
      const audioPlaying = localStorage.getItem('isAudioPlaying') === 'true';
      setIsAudioPlaying(audioPlaying);
      setIsHologramActive(audioPlaying);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const processAudio = () => {
    if (!analyserRef.current) return;
    dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);
    const updateAudioData = () => {
      analyserRef.current.getByteFrequencyData(dataArrayRef.current);
      const avg = dataArrayRef.current.reduce((sum, value) => sum + value, 0) / dataArrayRef.current.length;
      setAudioData(avg);
      requestAnimationFrame(updateAudioData);
    };
    updateAudioData();
  };

  const startListening = async () => {
    if (recognitionRef.current && !isListening) {
      recognitionRef.current.start();
      setIsListening(true);

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        sourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 256;
        sourceRef.current.connect(analyserRef.current);
        processAudio();
        setIsHologramActive(true);
      } catch (err) {
        console.error('Error accessing microphone:', err);
      }
    }
  };

  const stopListening = () => {
    if (recognitionRef.current && isListening) {
      recognitionRef.current.stop();
      setIsListening(false);
      setIsHologramActive(false);

      if (audioContextRef.current) {
        if (sourceRef.current) {
          sourceRef.current.mediaStream.getTracks().forEach((track) => track.stop());
        }
        audioContextRef.current.close();
        audioContextRef.current = null;
      }

      if (transcript.trim() !== '') {
        sendMessageToChatWidget(transcript);
        setTranscript('');
      }
    }
  };

  const stopAudioPlayback = () => {
    if (window.audioPlayer) {
      window.audioPlayer.pause();
      window.audioPlayer.currentTime = 0;
      setIsAudioPlaying(false);
      setIsHologramActive(false);
      localStorage.setItem('isAudioPlaying', 'false');
    }
  };

  const sendMessageToChatWidget = (message) => {
    const iframe = document.getElementById('voiceflow-iframe');
    if (iframe) {
      iframe.contentWindow.postMessage(
        {
          type: 'requestIntent',
          request: message,
        },
        '*'
      );
    }
  };

  const handleVoiceChange = (voiceId) => {
    setCurrentVoiceId(voiceId); // Update the context when a voice is selected
    setSelectedVoice(voiceId);  // Update local state for active button
  };

  return (
    <div className="voice-content">
      <div className="voice-controls-heading">
        <h4>AI Voice Controls</h4>
      </div>

      {/* Voice Selection Section */}
      <div className="voice-selection">
        <h5 className="voice-selection-title">Select Voice:</h5>
        <div className="voice-buttons">
          {voiceOptions.map((voice) => (
            <button
              key={voice.id}
              className={`voice-button ${selectedVoice === voice.id ? 'active' : ''}`}
              onClick={() => handleVoiceChange(voice.id)}
            >
              {voice.label}
            </button>
          ))}
        </div>
      </div>

      {/* Circle Button for Start/Stop Recording or Cancel Audio Playback */}
      <div className="voice-controls">
        {!isListening && !isAudioPlaying ? (
          <button onClick={startListening} className="circle-button">
            <FaMicrophone size={50} />
          </button>
        ) : isListening ? (
          <button onClick={stopListening} className="circle-button active">
            <FaStop size={50} />
          </button>
        ) : (
          <button onClick={stopAudioPlayback} className="circle-button active">
            <FaTimesCircle size={50} />
          </button>
        )}
      </div>

      <div className={`transcript-container ${isListening ? 'show' : 'hide'}`}>
        <p className="transcript">{transcript}</p>
      </div>

      <div className={`hologram-wrapper ${isHologramActive ? 'active' : ''}`}>
        <VoiceHologram audioData={audioData} />
      </div>
    </div>
  );
}

export default VoiceTranscription;
