// VoiceHologram.js
import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { MeshDistortMaterial, Sphere, OrbitControls } from '@react-three/drei';

function HologramSphere({ audioData }) {
  const meshRef = useRef();

  useFrame(() => {
    if (meshRef.current) {
      // Dynamically update the size and rotation of the sphere based on the audio data
      const scale = Math.max(0.5, audioData / 80); // Decrease scaling factor
      meshRef.current.scale.set(scale, scale, scale);
      meshRef.current.rotation.y += 0.02; // Rotate the sphere slowly
      meshRef.current.rotation.x += 0.01; // Rotate the sphere slowly
    }
  });

  return (
    <Sphere args={[2, 64, 64]} ref={meshRef}> {/* Set smaller initial radius */}
      <MeshDistortMaterial
        color="white"
        attach="material"
        distort={0.6}
        speed={1}
        roughness={0.1}
      />
    </Sphere>
  );
}

function VoiceHologram({ audioData }) {
    return (
      <div
        className="voice-hologram-container"
        style={{
          border: 'none', // Ensure no inline borders are set
          borderRadius: 0,
          boxShadow: 'none',
          outline: 'none',
        }}
      >
        <Canvas camera={{ position: [0, 0, 5] }}>
          <ambientLight intensity={0.4} />
          <directionalLight position={[0, 5, 5]} intensity={1} />
          <HologramSphere audioData={audioData} />
          <OrbitControls enableZoom={false} />
        </Canvas>
      </div>
    );
  }
  
export default VoiceHologram;
