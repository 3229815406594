// src/services/projectService.js

/* ============================================================
   Import Statements
============================================================ */
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll,
} from 'firebase/storage';

import {
  uploadDocumentToVoiceflow,
  attachTagToDocument,
} from './voiceflowService';
import {
  query,
  updateDoc,
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
  arrayRemove,
  arrayUnion,
  where,
} from 'firebase/firestore';

import axios from 'axios';



import { db, auth, storage } from '../firebase'; 
import {sendLaunchRequest} from './voiceflowService';


// Adjust the import paths as needed



/* ============================================================
   Project Services
============================================================ */

// Function to create a new project
export async function createProject(projectName, onLoginClick) {
  const user = auth.currentUser;
  if (!user) {
    console.error('No user is logged in');
    // Trigger the login modal
    if (onLoginClick) {
      onLoginClick(); // Call the function to open the login modal
    }
    return;
  }

  const projectsRef = collection(db, 'users', user.uid, 'projects');

  try {
    const projectDoc = await addDoc(projectsRef, {
      projectName,
      creationDate: new Date().toISOString(),
    });

    //console.log('New project created with ID:', projectDoc.id);
    return projectDoc.id;
    

  } catch (error) {
    console.error('Error creating project:', error);
  }
}

// Function to get the user's projects
export async function getUserProjects(uid) {
  const projectsRef = collection(db, 'users', uid, 'projects');
  const projectSnapshot = await getDocs(projectsRef);
  return projectSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

// Function to delete a project
export async function deleteProject(projectId) {
  const user = auth.currentUser;
  if (!user) {
    console.error('No user is logged in');
    return;
  }

  const projectRef = doc(db, 'users', user.uid, 'projects', projectId); // Reference to the specific project
  const storageFolderRef = ref(storage, `projects/${projectId}/`); // Reference to the storage folder

  try {
    // List all files in the storage folder
    const fileList = await listAll(storageFolderRef);

    // Delete each file in the folder
    const deletePromises = fileList.items.map(fileRef => {
      return deleteObject(fileRef).catch(error => {
        console.error(`Failed to delete ${fileRef.fullPath} from storage:`, error);
      });
    });

    // Wait for all files to be deleted
    await Promise.all(deletePromises);

    // After deleting the files, delete the project document from Firestore
    await deleteDoc(projectRef);

    //console.log('Project and associated files deleted successfully');
  } catch (error) {
    console.error('Error deleting project and associated files:', error);
  }
}

// Function to add sub-data to a project
export async function addSubDataToProject(projectId, subData) {
  const user = auth.currentUser;
  if (!user) {
    console.error('No user is logged in');
    return;
  }

  const projectRef = doc(db, 'users', user.uid, 'projects', projectId);

  try {
    // Update the project document with the fields in subData directly
    await updateDoc(projectRef, subData);

    //console.log('Sub-data updated within the project document');
    return projectId;
  } catch (error) {
    console.error('Error updating sub-data within the project document:', error);
    throw error;
  }
}

// Function to get sub-data for a project
export async function getSubDataForProject(projectId) {
  const user = auth.currentUser;
  if (!user) {
    console.error('No user is logged in');
    return null;
  }

  const projectRef = doc(db, 'users', user.uid, 'projects', projectId);
  try {
    const projectDoc = await getDoc(projectRef);
    if (projectDoc.exists()) {
      const data = projectDoc.data();
      //console.log('Fetched subData from Firestore:', data);
      return data || {}; // Return the entire document data as the "subData"
    } else {
      //console.log('No such project document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching sub-data:', error);
    throw error;
  }
}

// Function to upload a file
export async function uploadFile(file, projectId) {
  try {
    // Upload file to Firebase Storage
    const storageRef = ref(storage, `projects/${projectId}/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    let downloadURL = await getDownloadURL(snapshot.ref);
    console.log('File uploaded to Firebase, URL:', downloadURL);

    // Modify the URL for inline preview if the file is not a PDF
    if (!file.type.includes('pdf') && !file.type.startsWith('image/')) {
      downloadURL = modifyUrlForPreview(downloadURL, file.type);
      console.log('Modified URL for preview:', downloadURL);
    }

    // Now upload to Voiceflow
    const documentResponse = await uploadDocumentToVoiceflow(downloadURL);
    console.log('Document uploaded to Voiceflow:', documentResponse);

    // Attach tag to the uploaded document in Voiceflow
    const documentId = documentResponse.data.documentID;
    await attachTagToDocument(documentId, projectId);

    return downloadURL;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}

// Function to modify URL for different document types
function modifyUrlForPreview(url, fileType) {
  if (fileType.includes('vnd.openxmlformats-officedocument') || fileType.includes('application/msword') || fileType.includes('application/vnd.ms-excel') || fileType.includes('application/vnd.ms-powerpoint')) {
    // Use Google Docs Viewer for document types
    return `https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`;
  } else if (fileType === 'application/msword' || fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.ms-powerpoint') {
    // Use Office Live Viewer for older Office formats
    return `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`;
  }
  // Return the original URL for unsupported or unknown file types
  return url;
}



// Function to delete a file from storage and Firestore
export async function deleteFileFromStorageAndFirestore(projectId, fileUrl) {
  const user = auth.currentUser;
  if (!user) {
    console.error('No user is logged in');
    return;
  }

  // Step 1: Extract the original Firebase URL if the URL is a preview link
  const originalFirebaseUrl = extractFirebaseUrlFromPreview(fileUrl);

  const fileRef = ref(storage, originalFirebaseUrl);

  try {
    // Step 2: Delete the file from Firebase Storage
    await deleteObject(fileRef);
    console.log('File deleted from Firebase Storage');

    // Step 3: Remove the file reference from the Firestore document
    const projectRef = doc(db, 'users', user.uid, 'projects', projectId);
    await updateDoc(projectRef, {
      files: arrayRemove(fileUrl), // Remove the original preview URL from Firestore
    });

    console.log('File reference removed from Firestore');
  } catch (error) {
    console.error('Error deleting file or updating Firestore:', error);
  }
}

// Helper function to extract the original Firebase URL from the preview link
function extractFirebaseUrlFromPreview(previewUrl) {
  try {
    const url = new URL(previewUrl);

    // If it's a Google Docs preview URL, extract the actual Firebase Storage URL
    if (url.host === 'docs.google.com' || url.host.includes('view.officeapps.live.com')) {
      const params = new URLSearchParams(url.search);
      const firebaseUrl = params.get('url') || params.get('src');
      if (firebaseUrl) {
        const decodedUrl = decodeURIComponent(firebaseUrl);
        console.log('Extracted Firebase URL:', decodedUrl);
        return decodedUrl;
      }
    }
  } catch (error) {
    console.error('Error extracting Firebase URL from preview link:', error);
  }

  // If extraction fails, return the original URL
  return previewUrl;
}

// Function to append to chat history
export async function appendToChatHistory(projectId, newTurn) {
  const user = auth.currentUser;
  if (!user) {
    console.error('No user is logged in');
    return;
  }

  const projectRef = doc(db, 'users', user.uid, 'projects', projectId);

  try {
    // Use arrayUnion to append the new turn to the existing chatHistory array
    await updateDoc(projectRef, {
      chatHistory: arrayUnion(newTurn), // Append new turn to chat history
    });

    //console.log('New turn appended to chat history in the project document');
    return projectId;
  } catch (error) {
    console.error('Error appending new turn to chat history:', error);
    throw error;
  }
}

// Function to upload an image
export async function uploadimage(file, projectId) {
  try {
    console.log('Uploading file to Firebase:', file.name);
    const storageRef = ref(storage, `projects/${projectId}/${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    // console.log('File uploaded to Firebase, URL:', downloadURL);
    // projectId used as the tag

    return downloadURL;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
}

// Function to fetch linked students
export const fetchLinkedStudents = async (schoolName, parentEmail) => {
  try {
    // Query the Firestore collection for students with the given school and parentEmail
    const studentQuery = query(
      collection(db, `School/${schoolName}/Students`), // Navigate to the correct school collection
      where('parentEmail', '==', parentEmail)
    );
    const studentSnapshot = await getDocs(studentQuery);

    if (studentSnapshot.empty) {
      return [];
    }

    // Map the results to extract student data
    const students = studentSnapshot.docs.map(doc => ({
      studentName: doc.id, // Assuming the student name is the document ID
      studentData: doc.data(),
    }));

    return students;
  } catch (error) {
    console.error("Error fetching linked students:", error);
    throw error;
  }
};


export const updateProjectName = async (projectId, newName) => {
  const user = auth.currentUser;
  if (!user) {
    console.error('No user is logged in');
    return;
  }

  const projectRef = doc(db, 'users', user.uid, 'projects', projectId);

  try {
    await updateDoc(projectRef, {
      projectName: newName,
    });
    console.log('Project name updated successfully');
  } catch (error) {
    console.error('Error updating project name:', error);
    throw error;
  }
};

export const getParentEmailFromStudent = async (username) => {
  try {
    const normalizedUsername = username.toLowerCase();
    const studentDocRef = doc(db, 'GlobalStudents', normalizedUsername);
    const studentDoc = await getDoc(studentDocRef);

    if (studentDoc.exists()) {
      const studentData = studentDoc.data();
      return {
        parentEmail: studentData.parentEmail || null,
        studentEmail: studentData.studentEmail || null, // Ensure studentEmail is available
      };
    } else {
      console.error('No student found with this username');
      return null;
    }
  } catch (error) {
    console.error('Error retrieving parent or student email:', error);
    return null;
  }
};
