/* ============================================================
   Import Statements
============================================================ */

import React, { useState } from 'react';
import { COMPANY_NAME } from '../../config.js';


// Firebase Authentication Functions
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
} from 'firebase/auth';
import { auth } from '../../firebase';

// Services
import { studentLogin } from './studentAuthService.js';
import { fetchLinkedStudents} from './ParentLogin'; // New function to fetch parent's email
import {getParentEmailFromStudent } from '../../services/projectService.js'; // New function to fetch parent's email
// Import ParentSignup component
import ParentSignup from './ParentSignup';

// Assets
import logo from '../../assets/logo/logo.png';
import googleLogo from '../../assets/logo/google-logo.png';

// CSS
import './Login.css';
import '../../styles/global.css';

/* ============================================================
   Login Component
============================================================ */

function Login({ onClose }) {
  /* ============================================================
     State Variables
  ============================================================ */
  const [isSignUp, setIsSignUp] = useState(false);
  const [showStudentLogin, setShowStudentLogin] = useState(false);
  const [showParentLogin, setShowParentLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showParentSignup, setShowParentSignup] = useState(false); // New state for showing ParentSignup

  const [schoolName, setSchoolName] = useState('');
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [linkedStudents, setLinkedStudents] = useState([]);
const [successMessage, setSuccessMessage] = useState('');

  /* ============================================================
     Event Handlers and Helper Functions
  ============================================================ */

  const handleFormToggle = () => {
    setIsSignUp(!isSignUp);
    setError('');
    setMessage('');
  };

  const formatFirebaseError = (message) => {
    return message.replace('Firebase:', '').replace(/[\(\)]/g, '').trim();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (isSignUp && password !== confirmPassword) {
      setError('Passwords do not match');
      setTimeout(() => setError(''), 2000);
      return;
    }
  
    try {
      if (isSignUp) {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          emailOrUsername,
          password
        );
        const user = userCredential.user;
        console.log('User signed up:', user);
  
        // Send email verification after signup
        
        setSuccessMessage('Signup successful! Please verify your email.');
      } else {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          emailOrUsername,
          password
        );
        const user = userCredential.user;
  
        if (!user.emailVerified) {
          setError('Please verify your email before logging in.');
        } else {
          console.log('User logged in:', user);
          setError('');
        }
      }
    } catch (error) {
      console.error('Error:', error.message);
      setError(formatFirebaseError(error.message));
      setTimeout(() => setError(''), 2000);
    }
  };
  

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      console.log('Google sign-in successful:', result.user);
      setError('');
    } catch (error) {
      console.error('Error with Google sign-in:', error.message);
      setError(formatFirebaseError(error.message));
      setTimeout(() => setError(''), 2000);
    }
  };

  const handleStudentLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await studentLogin(schoolName, emailOrUsername, password);
      console.log('Student logged in:', user);
      setError('');
    } catch (error) {
      console.error('Error:', error.message);
      setError('Student login failed. Please check your credentials.');
    }
  };

  const handleParentLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Fetching linked students with:', { schoolName, emailOrUsername });
      const students = await fetchLinkedStudents(schoolName, emailOrUsername);
      if (students.length === 0) {
        setError('No students found for the provided email and school.');
      } else {
        setLinkedStudents(students);
        setError('');
      }
    } catch (err) {
      setError('An error occurred while fetching students.');
      console.error(err);
    }
  };

  const handleStudentSelection = async (student) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        student.studentData.studentEmail,
        'password123' // Use the password stored in your system
      );
      console.log(`Logged in as student: ${student.studentName}`);
    } catch (error) {
      console.error('Error logging into student account:', error);
      setError('Failed to log into student account.');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  // New function to handle password reset based on student's username
  
  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
  
    // Regular expression to check if the input is an email
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailOrUsername);
  
    try {
      if (isEmail) {
        // Standard password reset for regular users using email
        console.log('Sending password reset email for:', emailOrUsername);
        await sendPasswordResetEmail(auth, emailOrUsername);
        setMessage('Password reset email sent to your email address.');
      } else {
        // Handle custom student username flow
        console.log('Handling student username reset process for:', emailOrUsername);
  
        // Lookup the student's data using the username
        const studentDoc = await getParentEmailFromStudent(emailOrUsername);
  
        if (!studentDoc) {
          setError('No student found with this username.');
          return;
        }
  
        const { parentEmail, studentEmail } = studentDoc;
  
        if (!parentEmail || !studentEmail) {
          setError('Parent or student email not found for the given username.');
          return;
        }
  
        // Initialize Firebase Functions
        const functions = getFunctions();  // Add this line
  
        // Generate the reset link for the student
        const generateResetLink = httpsCallable(functions, 'generateStudentPasswordResetLink');
        const result = await generateResetLink({ studentEmail });
        const resetLink = result.data.resetLink;
  
        // Call the Cloud Function to send the email to the parent
        const sendEmail = httpsCallable(functions, 'sendPasswordResetEmailToParent');
        console.log('Sending password reset email to parent:', { parentEmail, resetLink, emailOrUsername });
        await sendEmail({ parentEmail, resetLink, emailOrUsername   });
  
        setMessage(`Password reset email has been sent to your parent's email`);
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError(formatFirebaseError(error.message));
    }
  };
  
  
  

  // New function to show the ParentSignup form
  const handleStudentRegister = () => {
    setShowParentSignup(true); // Show ParentSignup when Student Register button is clicked
  };

  /* ============================================================
     Render Component
  ============================================================ */
  return (
    <div
      className={`login-container ${
        showStudentLogin
          ? 'student-active'
          : showParentLogin
          ? 'parent-active'
          : showForgotPassword
          ? 'forgot-password-active'
          : ''
      }`}
      onClick={onClose}
    >
      <div className="container" onClick={(e) => e.stopPropagation()}>
        <div className="left-panel">
          <div className="brand">
            <img src={logo} alt="Naxos.ai Logo" />
            <h1>
              {showStudentLogin
                ? 'Student Portal'
                : showParentLogin
                ? 'Parent Login'
                : showForgotPassword
                ? 'Forgot Password'
                : isSignUp
                ? 'Sign Up Now!'
                : 'Log In Now!'}
            </h1>
          </div>
  
          {/* Conditionally Render Forms */}
          {showForgotPassword ? (
            <>
              <div className="login-options">
                <p className="form-toggle-text">
                  <a href="#" onClick={() => setShowForgotPassword(false)}>
                    ← Back to Login
                  </a>
                </p>
              </div>
              <form onSubmit={handleForgotPasswordSubmit}>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Enter Username or Email"
                    value={emailOrUsername}
                    onChange={(e) => setEmailOrUsername(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="login-button">
                  Send Password Reset Email
                </button>
              </form>
            </>
          ) : showStudentLogin ? (
            <>
              <div className="login-options">
                <p className="form-toggle-text">
                  <a href="#" onClick={() => setShowStudentLogin(false)}>
                    ← Back
                  </a>
                </p>
                <p className="form-toggle-text">
                  <a
                    href="#"
                    onClick={() => {
                      setShowParentLogin(true);
                      setShowStudentLogin(false);
                    }}
                  >
                    {/*Parent Log In*/}
                  </a>
                </p>
              </div>
              <form onSubmit={handleStudentLoginSubmit}>
               {/* <div className="input-group">
                  <input
                    type="text"
                    placeholder="School Name"
                    value={schoolName}
                    onChange={(e) => setSchoolName(e.target.value)}
                    required
                  />
                </div>*/}
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Username"
                    value={emailOrUsername}
                    onChange={(e) => setEmailOrUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span className="icon" onClick={togglePasswordVisibility}>
                    {passwordVisible ? 'Hide' : 'Show'}
                  </span>
                </div>
                <button type="submit" className="login-button">
                  Log In
                </button>
              </form>
  
              <p className="forgot-password-text">
                <a href="#" onClick={() => setShowForgotPassword(true)}>
                  Forgot Password?
                </a>
              </p>
  
              <button className="student-register-button" onClick={handleStudentRegister}>
                Register New Student
              </button>
            </>
          ) : showParentLogin ? (
            <>
              <div className="login-options">
                <p className="form-toggle-text">
                  <a
                    href="#"
                    onClick={() => {
                      setShowParentLogin(false);
                      setShowStudentLogin(false);
                    }}
                  >
                    ← Back
                  </a>
                </p>
                <p className="form-toggle-text">
                  <a
                    href="#"
                    onClick={() => {
                      setShowParentLogin(false);
                      setShowStudentLogin(true);
                    }}
                  >
                    Student Login
                  </a>
                </p>
              </div>
              <form onSubmit={handleParentLoginSubmit}>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="School Name"
                    value={schoolName}
                    onChange={(e) => setSchoolName(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <input
                    type="email"
                    placeholder="Parent Email"
                    value={emailOrUsername}
                    onChange={(e) => setEmailOrUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span className="icon" onClick={togglePasswordVisibility}>
                    {passwordVisible ? 'Hide' : 'Show'}
                  </span>
                </div>
                <button type="submit" className="login-button">
                   Log in as Parent 
                </button>
                <p className="forgot-password-text">
                  <a href="#" onClick={() => setShowForgotPassword(true)}>
                    Forgot Password?
                  </a>
                </p>
              </form>
  
              {linkedStudents.length > 0 && (
                <div>
                  <h3>Select an Enrolled Student</h3>
                  {linkedStudents.map((student, index) => (
                    <button
                      key={index}
                      onClick={() => handleStudentSelection(student)}
                      className="student-button"
                    >
                      {student.studentName}
                    </button>
                  ))}
                </div>
              )}
            </>
          ) : (
            <>
              <p className="form-toggle-text">
                {isSignUp ? (
                  <>
                    Already have an account?{' '}
                    <a href="#" onClick={handleFormToggle}>
                      Log in
                    </a>
                  </>
                ) : (
                  <>
                    Don't have an account?{' '}
                    <a href="#" onClick={handleFormToggle}>
                      Sign up
                    </a>
                  </>
                )}
              </p>
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Email"
                    value={emailOrUsername}
                    onChange={(e) => setEmailOrUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span className="icon" onClick={togglePasswordVisibility}>
                    {passwordVisible ? 'Hide' : 'Show'}
                  </span>
                </div>
                {isSignUp && (
                  <div className="input-group">
                    <input
                      type={confirmPasswordVisible ? 'text' : 'password'}
                      placeholder="Confirm Password"
                      id="confirm-password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <span className="icon" onClick={toggleConfirmPasswordVisibility}>
                      {confirmPasswordVisible ? 'Hide' : 'Show'}
                    </span>
                  </div>
                )}
                <button type="submit" className="login-button">
                  {isSignUp ? 'Sign up' : 'Log in'}
                </button>
                {!isSignUp && (
                  <p className="forgot-password-text">
                    <a href="#" onClick={() => setShowForgotPassword(true)}>
                      Forgot Password?
                    </a>
                  </p>
                )}
             </form>
<button className="google-signin-button" onClick={handleGoogleSignIn}>
  <img src={googleLogo} alt="Google Logo" className="google-logo" />
  Sign in with Google
</button>

{!showStudentLogin && (
  <button
    className="student-register-button"
    onClick={() => {
      if (isSignUp) {
        setShowParentSignup(true);
      } else {
        setShowStudentLogin(true);
      }
    }}
  >
    {isSignUp ? 'Register New Student' : 'Continue to Student Portal'}
  </button>
)}


              
            </>
          )}
        </div>
  
        {showParentSignup && <ParentSignup onClose={() => setShowParentSignup(false)} />}
  
        <div className="right-panel">
          <div className="illustration">
            {error ? (
              <h2 className="error-message">{error}</h2>
            ) : message ? (
              <h2 className="success-message">{message}</h2>
            ) : (
              <>
                {isSignUp ? (
                  <>
                    <h2>Join Us</h2>
                    <img src={logo} alt="Sign Up Illustration" className="ai-illustration" />
                    <p>Sign up to explore {COMPANY_NAME}</p>
                  </>
                ) : showStudentLogin ? (
                  <>
                    <h2>Student Login</h2>
                    <img src={logo} alt="Student Login Illustration" className="ai-illustration" />
                    <p>Welcome to {COMPANY_NAME} Student Login</p>
                  </>
                ) : showParentLogin ? (
                  <>
                    <h2>Parent Portal</h2>
                    <img src={logo} alt="Parent Login Illustration" className="ai-illustration" />
                    <p>Manage your child's account on {COMPANY_NAME}</p>
                  </>
                ) : showForgotPassword ? (
                  <>
                    <h2>Reset Password</h2>
                    <img src={logo} alt="Forgot Password Illustration" className="ai-illustration" />
                    <p>Enter your email or username and we will send a password reset link to the asociated email address</p>
                  </>
                ) : (
                  <>
                    <h2>Welcome Back!</h2>
                    <img src={logo} alt="Login Illustration" className="ai-illustration" />
                    <p>Log in to continue using {COMPANY_NAME}</p>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
