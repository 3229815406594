// Import statements organized by functionality

// React Import
import React from 'react';

// Icon Imports
import { FaSignOutAlt, FaSignInAlt, FaUser, FaCog } from 'react-icons/fa';

// CSS Import
import './SettingsBox.css'; // Ensure to create and style this CSS file
import '../../styles/global.css';

function SettingsBox({ isLoggedIn, onLogout, onLogin }) {
  /* ============================================================
     Render Component
  ============================================================ */
  return (
    <div className="settings-box">
      {isLoggedIn ? (
        <>
          {/* Logout Option */}
          <div className="settings-item" onClick={onLogout}>
            <FaSignOutAlt className="settings-icon" /> Logout
          </div>

       

          
        </>
      ) : (
        /* Login Option */
        <div className="settings-item" onClick={onLogin}>
          <FaSignInAlt className="settings-icon" /> Login
        </div>
      )}
    </div>
  );
}

export default SettingsBox;
