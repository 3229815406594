import React from 'react';
import './HelpPage.css'; // External CSS file for styling

function HelpPage() {
  return (
    <div className="main-content">
      {/* Welcome Section */}
      <div className="content-area">
        <h1 className="page-title">Welcome to our Tutorials!</h1>
        <p className="intro-text" style={{ textAlign: 'center' }}>
          Canopy.School is an AI platform that empowers students to learn more effectively by providing personalized, interactive support tailored to their unique needs.
        </p>

        {/* Quick Menu */}
        <div className="quick-menu" style={{ textAlign: 'center', marginBottom: '20px' }}>
          <h3>Quick Links</h3>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li><a href="#student-login">How to Login as a Student</a></li>
            <li><a href="#parent-login">How to Login as a Parent</a></li>
            <li><a href="#create-project">How to Create a New Project</a></li>
            <li><a href="#draw-tool">How to Use the Draw Tool</a></li>
          </ul>
        </div>

        {/* Section 1: How to Login as a Student */}
        <div id="student-login" className="tutorial-section">
          <h2 style={{ textAlign: 'center' }}>How to Login as a Student</h2>
          <p style={{ textAlign: 'center' }}>
            This tutorial covers how students can log into the platform. Ensure students have their credentials ready, and follow these step-by-step instructions to access their accounts and resources.
          </p>
          <div className="video-container">
            <iframe
              src="https://firebasestorage.googleapis.com/v0/b/naxosai-7461a.appspot.com/o/Tutorials%2FStudent%20login.mp4?alt=media&token=0918b9a9-af9e-406b-bc39-260145a05682"
              title="How to Login as a Student"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        {/* Section 2: How to Login as a Parent */}
        <div id="parent-login" className="tutorial-section">
          <h2 style={{ textAlign: 'center' }}>How to Login as a Parent</h2>
          <p style={{ textAlign: 'center' }}>
            Parents can log into the platform using their email. Follow this guide to learn how parents can monitor their child's progress and interact with the system to support their learning journey.
          </p>
          <div className="video-container">
            <iframe
              src="https://firebasestorage.googleapis.com/v0/b/naxosai-7461a.appspot.com/o/Tutorials%2FParent%20Login.mp4?alt=media&token=bd90bbc8-80b5-48ee-bef6-6c28cd913d1c"
              title="How to Login as a Parent"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        {/* Section 3: How to Create a New Project */}
        <div id="create-project" className="tutorial-section">
          <h2 style={{ textAlign: 'center' }}>How to Create a New Project</h2>
          <p style={{ textAlign: 'center' }}>
            Creating a new project is simple. Follow this tutorial to learn how to start a new project and optimize your learning experience.
          </p>
          <div className="video-container">
            <iframe
              src="https://firebasestorage.googleapis.com/v0/b/naxosai-7461a.appspot.com/o/Tutorials%2FHow%20to%20create%20new%20projects%20.mp4?alt=media&token=6935c4ee-c370-405c-b285-19ecb50d1470"
              title="How to Create a New Project"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        {/* Section 4: How to Use the Draw Tool */}
        <div id="draw-tool" className="tutorial-section">
          <h2 style={{ textAlign: 'center' }}>How to Use the Draw Tool</h2>
          <p style={{ textAlign: 'center' }}>
            The draw tool in Voiceflow allows users to visually map out ideas or systems and receive real-time assistance from AI. This guide will help you understand how to use the draw tool effectively.
          </p>
          <div className="video-container">
            <iframe
              src="https://firebasestorage.googleapis.com/v0/b/naxosai-7461a.appspot.com/o/Tutorials%2FDraw%20Tool%20Guide.mp4?alt=media&token=c4ac3f27-fb91-4b82-90ae-3ea3c9417cfe"
              title="How to Use the Draw Tool"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpPage;
