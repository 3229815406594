export function extractFileNameFromUrl(url) {
    try {
      // If the URL is a Google Docs Viewer or Office Viewer link, extract the Firebase URL part
      const match = url.match(/(?:url=)(https[^&]*)/);
      const firebaseUrl = match ? decodeURIComponent(match[1]) : url;
  
      // Decode and split to get the file name
      const decodedUrl = decodeURIComponent(firebaseUrl);
      const fileName = decodedUrl.split('/').pop().split('?')[0];
  
      // Limit the file name to 10 characters, adding "..." if truncated
      const truncatedFileName = fileName.length > 12 ? `${fileName.substring(0, 12)}...` : fileName;
  
      return truncatedFileName;
    } catch (error) {
      console.error('Error extracting file name:', error);
      return 'Unknown File'; // Fallback for error cases
    }
  }
  