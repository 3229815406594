// Define the voice options in an array of objects
const voiceOptions = [
    { id: 'cgSgspJ2msm6clMCkdW9', label: 'Jessica' },
    { id: 'cjVigY5qzO86Huf0OWal', label: 'Eric' },
    { id: 'Xb7hH8MSUJpSbSDYk0k2', label: 'Alice' }, // Example voice ID
    { id: 'bIHbv24MWmeRgasZH58o', label: 'Will' },
    { id: 'nPczCjzI2devNBz1zQrb', label: 'Brian' }, // Example voice ID
    { id: 'XrExE9yKIg1WjnnlVkGX', label: 'Matilda' }, // Example voice ID
  ];
  
  export default voiceOptions;
  