// VoiceContext.js
import React, { createContext, useState, useContext } from 'react';

// Create a Context
const VoiceContext = createContext();

// Create a Provider component
export const VoiceProvider = ({ children }) => {
  const [currentVoiceId, setCurrentVoiceId] = useState('cgSgspJ2msm6clMCkdW9');

  return (
    <VoiceContext.Provider value={{ currentVoiceId, setCurrentVoiceId }}>
      {children}
    </VoiceContext.Provider>
  );
};

// Custom Hook to use the VoiceContext
export const useVoiceContext = () => useContext(VoiceContext);
