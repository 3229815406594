import React, { useState, useEffect } from 'react';
import '../MainContent/MainContent.css';

function MainContent() {
  return (
    <div className="main-content">
      <div className="content-area">
        <iframe
          src="https://tools.canopy.school/calculator/"  // Replace this URL with the URL you want to embed
          width="100%"
          height="100%"
          style={{ border: 'none', borderRadius: '10px' }}  // Optional styling
          allowFullScreen
          title="Embedded Content"
        ></iframe>
      </div>
    </div>
  );
}

export default MainContent;
