import axios from 'axios';

const elevenLabsApiKey = process.env.REACT_APP_Eleven_Labs_API_KEY;
let audioPlayer = null; // Define audioPlayer globally

// Update the function to accept voiceId as a parameter
export const sendDataToUtility = async (logMessage, sessionData, voiceId = 'YOUR_DEFAULT_VOICE_ID') => {
  console.log("TTS Utility Function Called!");
  console.log("Received Log Message:", logMessage);

  const sessionDataString = JSON.stringify(sessionData, null, 2);
  console.log("Session Data (Stringified):", sessionDataString);

  if (sessionData && sessionData.turns && sessionData.turns.length > 0) {
    const lastMessage = sessionData.turns[sessionData.turns.length - 1];

    const extractMessageText = (message) => {
        if (message.type === 'user') {
          return message.message.replace(/[^a-zA-Z0-9,\s]/g, ''); // Remove symbols but keep letters, numbers, commas, and spaces
        } else if (message.type === 'system' && message.messages.length > 0) {
          return message.messages
            .map((msg) =>
              msg.text
                .map((child) =>
                  child.children.map((c) => c.text.replace(/[^a-zA-Z0-9,\s]/g, '')).join('')
                )
                .join('')
            )
            .join(' ');
        }
        return '';
      };
      

    const messageText = extractMessageText(lastMessage) || '';
    console.log("Extracted Last Message Text:", messageText);

    try {
      // Eleven Labs Text-to-Speech API Call with dynamic voice ID
      const response = await axios.post(
        `https://api.elevenlabs.io/v1/text-to-speech/${voiceId}`, // Use dynamic voice ID
        {
          text: messageText,
          voice_settings: {
            stability: 0.1,
            similarity_boost: 0.3,
            style: 0.2,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'xi-api-key': elevenLabsApiKey, // Replace with your Eleven Labs API Key
          },
          responseType: 'arraybuffer', // Important: Expect raw binary data
        }
      );

      const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const audioUrl = URL.createObjectURL(audioBlob);
      console.log('Generated Audio URL:', audioUrl);

      if (audioPlayer) {
        audioPlayer.pause();
        audioPlayer = null;
      }

      // Create a new audio element and set it globally
      audioPlayer = new Audio(audioUrl);
      window.audioPlayer = audioPlayer; // Attach audioPlayer to the window object

      audioPlayer.play().then(() => {
        console.log("Audio playback started!");
        localStorage.setItem('isAudioPlaying', 'true'); // Set localStorage when audio starts
      }).catch(error => {
        console.error("Error during audio playback:", error);
        localStorage.setItem('isAudioPlaying', 'false');
      });

      audioPlayer.onended = () => {
        console.log("Audio playback finished.");
        audioPlayer = null;
        window.audioPlayer = null; // Cleanup global reference
        localStorage.setItem('isAudioPlaying', 'false'); // Reset localStorage when audio ends
      };

    } catch (error) {
      console.error('Error sending message to Eleven Labs API:', error);
    }
  } else {
    console.log("No valid session data found or no messages available.");
  }
};

// Function to stop the audio playback
export const stopAudioPlayback = () => {
  if (audioPlayer && !audioPlayer.paused) {
    audioPlayer.pause();
    audioPlayer.currentTime = 0;
    console.log("Audio playback stopped.");
    localStorage.setItem('isAudioPlaying', 'false'); // Reset localStorage when playback is stopped manually
    audioPlayer = null; // Clear the global reference
  } else {
    console.log("No audio is currently playing.");
  }
};
