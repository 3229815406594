// src/prompts.js

const prompts = [
    "What's the best way to organize homework efficiently?",
    "Tips to complete difficult homework faster.",
    "Explain a homework question you need help with.",
    "How can I improve focus while doing homework?",
    "What's a fun way to practice math problems?",
    "Can you help me understand my assignments better.",
    "What's the best way to study for a test?",
    "How can I manage time for multiple homework assignments?",
    "How do I avoid distractions while studying?",
    "What’s the best way to revise for a quiz?"
];

  export default prompts;
  