import React, { useState, useEffect } from 'react';
import './StudentDashboard.css';
import '../../../styles/global.css';
import { getUserProjects } from '../../../services/projectService'; // Adjust path if needed

function StudentDashboard({ user }) {
  const [projects, setProjects] = useState([]);
  const [studentData, setStudentData] = useState(null); // State for student data
  const [authProvider, setAuthProvider] = useState(null); // State for authentication provider

  // Fetch projects and student data when the component mounts or when the user changes
  useEffect(() => {
    if (user) {
      // Fetch user projects
      getUserProjects(user.uid).then((fetchedProjects) => {
        setProjects(fetchedProjects); // Set the fetched projects in the state
      });

      // Determine the authentication provider
      if (user.providerData && user.providerData.length > 0) {
        setAuthProvider(user.providerData[0].providerId);
      } else {
        setAuthProvider(null);
      }

      // Retrieve student data from local storage using the correct key 'studentInfo'
      const storedData = localStorage.getItem('studentInfo');
      if (storedData) {
        setStudentData(JSON.parse(storedData));
      } else {
        setStudentData(null); // No student data found
      }
    } else {
      setProjects([]); // Reset projects when user logs out
      setStudentData(null); // Reset student data when user logs out
      setAuthProvider(null); // Reset auth provider
    }
  }, [user]);

  // Default placeholder values when no user is logged in
  const emailFirstLetter = user ? user.email.charAt(0).toUpperCase() : 'A'; // Default to 'A'

  // Use studentData if available, otherwise fall back to user.displayName or default values
  const name = studentData
    ? `${studentData.firstName} ${studentData.lastName}`
    : user
    ? user.displayName || 'Anonymous'
    : 'Guest';

  const email = user ? user.email : 'guest@example.com'; // Default to placeholder email

  const username = studentData
    ? studentData.username
    : user
    ? user.email.split('@')[0]
    : 'guest';

  const studentID = studentData ? studentData.studentID : null;
  const grade = studentData ? studentData.grade : null;

  // Set account type based on the presence of studentData
  const type = studentData ? 'Student' : 'Regular';

  return (
    <div className="main-content">
      <div className="profile-container">
        {/* Profile Image Placeholder (First Letter of Email) */}
        <div className="profile-image-container">
          <div className="profile-letter">{emailFirstLetter}</div>
        </div>

        {/* Badges */}
        <div className="badges-container">
          <div className="badge">
            <p>{projects.length}</p> {/* Number of projects dynamically displayed */}
            <span>Number of Projects</span>
          </div>
          <div className="badge">
            <p>n/a</p>
            <span>Daily Streak</span>
          </div>
          <div className="badge">
            <p>n/a</p>
            <span>My Stars</span>
          </div>
          <div className="badge">
            <p>n/a</p>
            <span>Points Earned</span>
          </div>
        </div>

        {/* User Information */}
        <div className="user-info">
          {/* Always display Email */}
          <div className="info-group">
            <label>Email:</label>
            <p>{email}</p> {/* Dynamic user email or default */}
          </div>

          {/* Conditionally display fields based on auth provider and availability of studentData */}
          {authProvider === 'password' && studentData && (
            // For email/password users with studentData available
            <>
              <div className="info-group">
                <label>Name:</label>
                <p>{name}</p>
              </div>
              <div className="info-group">
                <label>Username:</label>
                <p>{username}</p>
              </div>
              <div className="info-group">
                <label>Student ID:</label>
                <p>{studentID}</p>
              </div>
              <div className="info-group">
                <label>Grade:</label>
                <p>{grade}</p>
              </div>
            </>
          )}

          {/* For other authentication providers or when studentData is missing */}
          {authProvider !== 'password' && (
            <>
              <div className="info-group">
                <label>Name:</label>
                <p>{name}</p>
              </div>
              <div className="info-group">
                <label>Username:</label>
                <p>{username}</p>
              </div>
              {/* You can include other fields if necessary */}
            </>
          )}

          {/* Account Type */}
          <div className="info-group">
            <label>Account Type:</label>
            <p>{type}</p> {/* Dynamic account type */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentDashboard;
