// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDW_iyBT-I_BzPSxDQKQbFWeiOEqLdPMXg",
  authDomain: "naxosai-7461a.firebaseapp.com",
  projectId: "naxosai-7461a",
  storageBucket: "naxosai-7461a.appspot.com",
  messagingSenderId: "861019374471",
  appId: "1:861019374471:web:606748797d2a8f674fc922",
  measurementId: "G-2JGQG0CJSV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app)
export const storage = getStorage(app); 


export { db, auth, };
