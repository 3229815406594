import React, { useEffect, useRef, useState } from 'react';
import './DrawCanvas.css';
import { uploadimage } from '../../services/projectService'; // Adjust the import path

const DrawCanvas = () => {
  // Refs and State Variables
  const canvasRef = useRef(null);
  const [drawing, setDrawing] = useState(false);
  const [color, setColor] = useState('#05AE10');
  const [lineWidth, setLineWidth] = useState(5);
  const [erasing, setErasing] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  /* ============================================================
     Image Upload Handler
  ============================================================ */
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const canvas = canvasRef.current;
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          const scale = Math.min(
            canvas.width / img.width,
            canvas.height / img.height
          );
          const x = (canvas.width / 2) - (img.width / 2) * scale;
          const y = (canvas.height / 2) - (img.height / 2) * scale;
          ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  /* ============================================================
     Send to AI Handler
  ============================================================ */
  const handleSend = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const pixelData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

    // Check if canvas is empty
    const isCanvasEmpty = pixelData.every((pixel, index) => {
      return index % 4 === 3 && pixel === 0; // Alpha channel is 0 (transparent)
    });

    if (isCanvasEmpty) {
      alert("The canvas is empty. Please draw something before sending.");
      return;
    }

    // Set loading to true when the send process starts
    setLoading(true);

    // Convert canvas to Blob and upload
    canvas.toBlob(
      async (blob) => {
        const fileName = `canvas_${Date.now()}.png`;
        const file = new File([blob], fileName, { type: 'image/png' });

        try {
          const projectId = 'temporary'; // Temporary folder for image uploads
          const downloadURL = await uploadimage(file, projectId);

          // Post the image URL to the iframe
          const iframe = document.getElementById('voiceflow-iframe');
          if (iframe) {
            // Send a JSON string to the iframe
            iframe.contentWindow.postMessage(
              JSON.stringify({
                type: 'imageUrl',
                imageUrl: downloadURL,
              }),
              '*' // For cross-origin messaging
            );

            console.log('Image uploaded, download URL:', downloadURL);
          }
        } catch (error) {
          console.error('Error uploading canvas:', error);
        }

        // Set loading to false once the send process is finished
        setLoading(false);
      },
      'image/png'
    );
  };

  /* ============================================================
     Canvas Drawing Logic
  ============================================================ */
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Helper functions to get mouse position on canvas
    const getCanvasX = (event) => {
      const rect = canvas.getBoundingClientRect();
      return (event.clientX - rect.left) * (canvas.width / rect.width);
    };

    const getCanvasY = (event) => {
      const rect = canvas.getBoundingClientRect();
      return (event.clientY - rect.top) * (canvas.height / rect.height);
    };

    // Start drawing
    const startDrawing = (event) => {
      setDrawing(true);
      ctx.beginPath();
      ctx.moveTo(getCanvasX(event), getCanvasY(event));
    };

    // Stop drawing
    const stopDrawing = () => {
      setDrawing(false);
      ctx.closePath();
    };

    // Drawing function
    const draw = (event) => {
      if (!drawing) return;
      ctx.lineWidth = lineWidth;
      ctx.lineCap = 'round';
      ctx.strokeStyle = erasing ? '#FFFFFF' : color;
      ctx.lineTo(getCanvasX(event), getCanvasY(event));
      ctx.stroke();
    };

    // Event Listeners
    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mousemove', draw);

    // Cleanup on unmount
    return () => {
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('mouseup', stopDrawing);
      canvas.removeEventListener('mousemove', draw);
    };
  }, [drawing, color, lineWidth, erasing]);

  /* ============================================================
     Helper Functions
  ============================================================ */
  // Toggle between Pen and Eraser
  const togglePenEraser = () => {
    setErasing(!erasing);
  };

  // Clear the Canvas
  const handleClear = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  /* ============================================================
     Render Component
  ============================================================ */
  return (
    <div>
      {/* Controls */}
      <div className="controls">
        <input
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
        />
        <input
          type="range"
          min="1"
          max="20"
          value={lineWidth}
          onChange={(e) => setLineWidth(e.target.value)}
        />
        <button onClick={togglePenEraser}>
          {erasing ? 'Pen' : 'Eraser'}
        </button>
        <button onClick={handleClear}>Clear</button>
        <button onClick={() => document.getElementById('imageUpload').click()}>
          Upload
        </button>
        <input
          type="file"
          id="imageUpload"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
      </div>

      {/* Canvas Element */}
      <canvas
        ref={canvasRef}
        width={400}  // Fixed canvas drawing width
        height={400} // Fixed canvas drawing height
        style={{
          width: '100%', // Scales canvas to 100% width of its container
          border: '2px solid black',
          cursor: 'crosshair',
        }}
      />

      {/* Send Button and Loading Spinner */}
      <div className="send-button-container">
        <button className="send-button" onClick={handleSend} disabled={loading}>
          Send to AI
        </button>
        <div className={`loading-spinner ${loading ? 'active' : ''}`}></div>
      </div>
    </div>
  );
};

export default DrawCanvas;
