// Import statements organized by functionality
import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';

// Component imports
import TopBar from './components/TopBar/TopBar';
import Sidebar from './components/LeftSideBar/Sidebar';
import RightSidebar from './components/RightSideBar/RightSidebar';
import Login from './components/Login/Login';
import MainContent from './components/MainContent/MainContent';
import Calculator from './components/pages/Calculator.js';
import AIChatPage from './components/pages/AIChatPage';
import StudentDashboard from './components/pages/studentdashboard/StudentDashboard.js';
import ProjectManager from './components/ProjectManager/ProjectManager';
import HelpPage from './components/pages/Help Page/HelpPage';  // Add this line

// Service imports
import { getUserProjects } from './services/projectService.js';

// CSS import
import './App.css';

function App() {
  // State variables
  const [selectedMenuItem, setSelectedMenuItem] = useState('Top Bar');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [user, setUser] = useState(null);
  const [projects, setProjects] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const storedDarkMode = localStorage.getItem('isDarkMode');
    return storedDarkMode === 'true';
  });
  const [projectName, setProjectName] = useState('');

  // React Router hooks
  const navigate = useNavigate();
  const location = useLocation();

  // UseRef to track first render
  const isFirstRender = useRef(true);

  // Mapping between pathnames and menu items
  const pathnameToMenuItem = {
    '/ai-chat': 'AI Chat',
    '/projects': 'My Subjects',
    '/calculator': 'Ai Calculator',
    '/student-dashboard': 'Settings',
    '/help': 'Help',
  };

  const menuItemToPathname = {
    'AI Chat': '/ai-chat',
    'My Subjects': '/projects',
    'Ai Calculator': '/calculator',
    'Settings': '/student-dashboard',
    'Help': '/help',
  };

  // Redirect to "/projects" on app load if at root path
  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/projects');
    }
  }, [location.pathname, navigate]);

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setIsLoginVisible(false);
        getUserProjects(currentUser.uid).then(setProjects);
      }
    });
    return () => unsubscribe();
  }, []);

  // Update selected menu item based on current location
  useEffect(() => {
    const menuItem = pathnameToMenuItem[location.pathname] || 'Top Bar';
    setSelectedMenuItem(menuItem);
    if (menuItem !== 'AI Chat') {
      setProjectName('');
    }
  }, [location.pathname]);

  // Write to local storage whenever isDarkMode changes (but not on initial render)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    localStorage.setItem('isDarkMode', isDarkMode);
  }, [isDarkMode]);

  // Handle menu item click
  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
    const path = menuItemToPathname[menuItem] || '/';
    navigate(path);
  };

  // Handle profile click
  const handleProfileClick = () => {
    if (!user) {
      setIsLoginVisible(true);
    }
  };

  // Close login modal
  const closeLoginModal = () => {
    setIsLoginVisible(false);
  };

  // Toggle dark mode
  const handleToggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  // Display message if on mobile device
  if (isMobile) {
    return (
      <div className="mobile-message">
        <p>This application is best viewed on a larger screen!</p>
      </div>
    );
  }

  return (
    <div className={`app ${isDarkMode ? 'dark-mode' : ''}`}>
      <Sidebar
        onMenuItemClick={handleMenuItemClick}
        onProfileClick={handleProfileClick}
        selectedItem={selectedMenuItem}
        userEmail={user?.email}
        isDarkMode={isDarkMode}
        onToggleDarkMode={handleToggleDarkMode}
      />
      <div className="main-container">
        <TopBar
          title={selectedMenuItem === 'AI Chat' ? 'AI Chat' : selectedMenuItem}
          projectName={selectedMenuItem === 'AI Chat' ? projectName : ''}
        />
        <div className="content-container">
        <Routes>
  <Route path="/" element={<MainContent />} />
  <Route
    path="/ai-chat"
    element={
      <AIChatPage
        onLoginClick={() => setIsLoginVisible(true)}
        isDarkMode={isDarkMode}
        setProjectName={setProjectName}
      />
    }
  />
  <Route path="/calculator" element={<Calculator />} />
  <Route
    path="/projects"
    element={
      <ProjectManager
        projects={projects}
        setProjects={setProjects}
        onLoginClick={() => setIsLoginVisible(true)}
      />
    }
  />
  <Route
    path="/student-dashboard"
    element={
    <StudentDashboard user={user} />}
    
  />
  
  {/* Help Page Route */}
  <Route path="/help" 
  element={
  <HelpPage />} />  {/* Add this line */}
</Routes>

          <RightSidebar projects={projects} setProjects={setProjects} />
        </div>
      </div>
      {isLoginVisible && <Login onClose={closeLoginModal} />}
    </div>
  );
}

export default App;
