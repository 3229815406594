// src/services/studentAuthService.js

/* ============================================================
   Import Statements
============================================================ */

import { auth } from '../../firebase'; // Adjust the path as needed
import { signInWithEmailAndPassword } from 'firebase/auth';

import { db } from '../../firebase'; // Adjust the path to your Firestore config
import { getDoc, doc } from 'firebase/firestore';

/* ============================================================
   Student Authentication Service
============================================================ */

/**
 * Handles student login by username, school, and password.
 * @param {string} school - The name of the school.
 * @param {string} username - The username of the student.
 * @param {string} password - The password provided by the student.
 * @returns {Promise<object>} The authenticated user object.
 * @throws Will throw an error if login fails.
 */
export const studentLogin = async (school, username, password) => {
    try {
      // Existing code to retrieve studentDoc and studentData
      const studentDocRef = doc(db, `GlobalStudents`, username);
      const studentDoc = await getDoc(studentDocRef);
  
      if (!studentDoc.exists()) {
        throw new Error('No such student found with the provided username.');
      }
  
      // Extract student data
      const studentData = studentDoc.data();
      const email = studentData.studentEmail;
      const firstName = studentData.firstName;
      const lastName = studentData.lastName;
      const studentID = studentData.studentID;
      const grade = studentData.grade;
  
      // Log the student in using their email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
  
      // Prepare the data to store in local storage
      const studentInfo = {
        uid: userCredential.user.uid,
        school,
        username,
        studentID,
        firstName,
        lastName,
        grade,
        email,
      };
  
      // Store the data in local storage
      localStorage.setItem('studentInfo', JSON.stringify(studentInfo));
  
      console.log('Student logged in:', userCredential.user);

    //console.log('Student logged in:', userCredential.user);
    //console.log(`Student found after login: ${firstName} ${lastName} (${username}) (${studentID}) - Grade ${grade}`);
    return {
        user: userCredential.user,
        studentData: {
          username,
          studentID,
          firstName,
          lastName,
          grade,
        },
      };
    } catch (error) {
      console.error('Error logging in student:', error);
      throw error;
    }
  };
