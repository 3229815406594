// src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter
import App from './App';
import './index.css';
import { AuthProvider } from './AuthContext'; // Import the AuthProvider
import { VoiceProvider } from './VoiceContext'; // Import the VoiceProvider

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider> 
      <VoiceProvider> 
        <Router> 
          <App />
        </Router>
      </VoiceProvider>
    </AuthProvider>
  </React.StrictMode>
); 




{/*}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// Replace App with TestApp
import App from './AppTest';

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

*/}















