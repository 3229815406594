// React and Hooks
import React, { useState, useEffect } from 'react';

// Icon Imports
import { FaPlus, FaTrash,FaSlidersH } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import the FontAwesomeIcon component
import { faSlidersUp } from '@fortawesome/free-solid-svg-icons'; // Import the specific icon

// CSS Import
import './SubDataModal.css';
import '../../styles/global.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Utilities
import { extractFileNameFromUrl } from '../../services/Utilities';

function SubDataModal({
  isVisible,
  onClose,
  onSubmit,
  subDataFields,
  setSubDataFields,
  currentItemTitle,
  uploadedFiles = [],
  handleFileChange,
  handleDeleteFile,
}) {
  /* ============================================================
     State Variables
  ============================================================ */
  const [activeTab, setActiveTab] = useState(1);
  const [selectedColor, setSelectedColor] = useState('#fff');

  /* ============================================================
     Effect Hooks
  ============================================================ */
  useEffect(() => {
    if (Object.keys(subDataFields).length > 0) {
      setSelectedColor(subDataFields.backgroundColor || '#fff');
    }
  }, [subDataFields]);

  /* ============================================================
     Early Return if Modal is Not Visible
  ============================================================ */
  if (!isVisible) return null;

  /* ============================================================
     Event Handlers
  ============================================================ */
  // Handle input changes for form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubDataFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  // Handle color selection
  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setSubDataFields((prevFields) => ({
      ...prevFields,
      backgroundColor: color,
    }));
  };

  // Trigger file upload dialog
  const triggerFileUpload = () => {
    document.getElementById('file-upload').click();
  };

  /* ============================================================
     Preset Colors Array
  ============================================================ */
  const presetColors = [
    '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107',
    '#673AB7', '#9C27B0', '#3F51B5', '#2196F3', '#03A9F4',
    '#F44336', '#E91E63', '#FF5722', '#FF9800', '#FFC107',
    '#795548', '#9E9E9E', '#607D8B',
  ];

  /* ============================================================
     Render Component
  ============================================================ */
  return (
    <div className="modal-overlay">
      <div className="modal-card">
        {/* Modal Header */}
        <div className="modal-header">
          <h2>{currentItemTitle ? `Update ${currentItemTitle}` : 'Create New Entry'}</h2>
          <button className="modal-close" onClick={onClose}>×</button>
        </div>

        {/* Modal Tabs */}
        <div className="modal-tabs">
          <button
            className={`tab-button ${activeTab === 1 ? 'active' : ''}`}
            onClick={() => setActiveTab(1)}
          >
            Train AI
          </button>
          <button
            className={`tab-button ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => setActiveTab(2)}
          >
            Subject Info
          </button>
        </div>

        {/* Modal Body */}
        <div className="modal-body">
          <div className="tab-content">
            {/* Left Column */}
            <div className="left-column">
              {activeTab === 1 && (
                <>
                  <div className="modal-section">
                    <label className="modal-label">AI Name</label>
                    <input
                      type="text"
                      name="aiName"
                      placeholder="Enter AI Name"
                      value={subDataFields.aiName || ''}
                      onChange={handleInputChange}
                      className="modal-input"
                    />
                  </div>

                  <div className="modal-section">
                    <label className="modal-label">AI Personality</label>
                    <input
                      type="text"
                      name="aiPersonality"
                      placeholder="Enter AI Personality"
                      value={subDataFields.aiPersonality || ''}
                      onChange={handleInputChange}
                      className="modal-input"
                    />
                  </div>

                  <div className="modal-section">
                    <label className="modal-label">Knowledge Base</label>
                    <textarea
                      name="knowledgeBase"
                      placeholder="Enter Knowledge Base"
                      value={subDataFields.knowledgeBase || ''}
                      onChange={handleInputChange}
                      className="modal-textarea"
                    />
                  </div>
                </>
              )}

              {activeTab === 2 && (
                <>
                  <div className="modal-section">
                    <label className="modal-label">Subject</label>
                    <input
                      type="text"
                      name="subject"
                      placeholder="Enter subject"
                      value={subDataFields.subject || ''}
                      onChange={handleInputChange}
                      className="modal-input"
                    />
                  </div>

                  <div className="modal-section">
                    <label className="modal-label">Semester</label>
                    <select
                      name="semester"
                      value={subDataFields.semester || ''}
                      onChange={handleInputChange}
                      className="modal-select"
                    >
                      <option value="" disabled>Select Semester</option>
                      <option value="Fall">Fall</option>
                      <option value="Spring">Spring</option>
                      <option value="Summer">Summer</option>
                      <option value="Winter">Winter</option>
                    </select>
                  </div>

                  <div className="modal-section">
                    <label className="modal-label">Description</label>
                    <input
                      type="text"
                      name="description"
                      placeholder="Brief Description"
                      value={subDataFields.description || ''}
                      onChange={handleInputChange}
                      className="modal-input"
                    />
                  </div>
                </>
              )}
            </div>

            {/* Right Column */}
            <div className="right-column">
              {activeTab === 1 && (
                <div className="modal-section full-width">
                  <label className="modal-label">Attachments</label>
                  <div className="attachment-container">
                    <div className="file-upload-label" onClick={triggerFileUpload}>
                      <FaPlus className="plus-icon" />
                      <span className="upload-text">Upload File</span>
                    </div>
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleFileChange}
                      multiple
                      className="file-upload-input"
                      style={{ display: 'none' }}
                    />
                    {uploadedFiles.length > 0 && (
                      <ul className="file-list">
                        {uploadedFiles.map((file, index) => {
                           const fileName = typeof file === 'string' ? extractFileNameFromUrl(file) : file.name;


                          return (
                            <li key={index} className="file-item">
                              <a
                                href={typeof file === 'string' ? file : '#'}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {fileName}
                              </a>
                              <FaTrash
                                className="delete-icon"
                                onClick={() => handleDeleteFile(index)}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              )}

              {activeTab === 2 && (
                <div className="modal-section full-width">
                  <label className="modal-label">Choose a Color</label>
                  <div className="color-grid">
                    {presetColors.map((color, index) => (
                      <div
                        key={index}
                        className="color-block"
                        style={{
                          backgroundColor: color,
                          border: selectedColor === color ? '2px solid black' : 'none',
                          cursor: 'pointer',
                          width: '40px',
                          height: '40px',
                          margin: '5px',
                        }}
                        onClick={() => handleColorSelect(color)}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Modal Footer */}
        <div className="modal-footer">
          <button className="modal-save-button" onClick={handleSubmit}>
            Save
          </button>
          <button className="modal-cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default SubDataModal;
