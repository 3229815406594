import React from 'react';
import './MainContent.css';

function MainContent() {
  return (
    <div className="main-content">
      <div className="content-area">
        <h1>Welcome to Script</h1>
        <p>Get started by Script a task and Chat can do the rest. Not sure where to start?</p>
        <div className="actions">
          <button className="action-button">Write copy</button>
          <button className="action-button">Image generation</button>
          <button className="action-button">Create avatar</button>
          <button className="action-button">Write code</button>
        </div>
        <div className="chat-input">
          <input type="text" placeholder="Summarize the latest..." />
          <button>Send</button>
        </div>
      </div>
    </div>
  );
}

export default MainContent;
